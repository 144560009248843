/* Desktop View (default) */
.top-bar {
    background-image: url('../../../public/img/topBar/topbg5.png');
    background-size: cover;
    background-position: center;
    padding: 20px 100px;
    color: black;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.contact-info {
    display: flex;
    align-items: center;
    
}

.contact-info span {
    margin-right: 20px;
    color: black;
    position: relative;
    font-size: 18px;
}

.contact-info .email-icon {
  
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
}

.contact-info .phone-icon {
    /* border: 2px solid black; */
    border-radius: 5px;
    padding: 5px;
    margin-right: 10px;
}

.social-media a {
    color: black;
    background-color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    text-decoration: none;
}

.social-media a:hover {
    color: black;
    background-color: white;
}

.social-media a i {
    font-size: 20px;
}

/* Mobile View */
@media (max-width: 768px) {
    .top-bar {
        flex-direction: column;
        padding: 10px;
        align-items: center; /* Center the logo */
    }
    
    .contact-info, .social-media {
        display: none; /* Hide contact info and social media on mobile */
    }

    .logo {
        width: 100%; /* Make the logo take full width on mobile */
        display: flex;
        justify-content: center; /* Center the logo image */
    }

    .logo img {
        max-width: 100%; /* Ensure the logo image doesn't exceed its container */
        height: auto;
    }
}
