.cta-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  background-color: #333; /* Background color matching the image */
  color: #fff; /* Text color for better contrast */
}

.icons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.icons img {
  max-width: 300px; /* Adjust to the desired size */
  height: auto; /* Maintain aspect ratio */
}

.cta-content {
  text-align: center;
}

.cta-header {
  font-size: 18px;
  margin-bottom: 10px;
}

.cta-title {
  font-size: 24px;
  font-weight: bold;
  color: #ff6600; /* Orange color for the title */
  margin-bottom: 20px;
}

.cta-buttons {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.cta-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.cta-button-primary {
  background-color: #ff6600; /* Orange color for the primary button */
  color: #fff;
}

.cta-button-secondary {
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
}
