.gallery-container {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    
}

.image-row {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    gap: 10px; /* Adjust the gap between images */
}

.image-slide {
    display: inline-block;
    padding: 0; /* Remove padding to reduce gap */
    flex: 0 0 auto;
}

.image-slide img {
    width: 100%; /* Ensure images take full width of their container */
    height: auto;
    max-width: 300px; /* Set a maximum width to ensure uniform size */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-cards {
    display: flex;
    justify-content: space-around;
    margin-top: 30px;
}

.card {
   
    padding: 20px;
    width: 300px; /* Fixed width for the card */
    text-align: center;
    transition: transform 0.3s;
    position: relative; /* For positioning the pseudo-element */
    overflow: hidden; /* To ensure no overflow of images */
}

.gallery-section {
    position: relative;
    padding: 30px 0;
}

.section-title {
    text-align: center;
    margin-bottom: 30px;
}

.section-title h2 {
    font-size: 2.5em;
}

.thm-color {
    color: #e74c3c;
}

.img-box {
    position: relative;
    width: 100%; /* Make the box take the full width of the slider */
    max-width: 250px; /* Adjust the maximum width for smaller images */
    margin: 0 auto; /* Center the images */
}

.img-box img {
    width: 100%; /* Ensure images take full width of their container */
    height: auto; /* Maintain aspect ratio */
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.slide-item {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px; /* Add padding around images */
}

.img-box img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.text-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.5s ease;
}

/* .slide-item:hover .text-overlay {
    opacity: 1;
} */

.slick-dots {
    bottom: 10px;
}

.card img {
    width: 100%; /* Make image cover the full width of the card */
    height: auto; /* Maintain aspect ratio */
    object-fit: cover; /* Ensure the image covers the container */
}

.card:hover {
    transform: scale(1.05);
}

.card::after {
    content: '';
    display: block;
    height: 2px;
    width: 100%;
    background-color: orange;
    margin: 10px auto 0;
}

/* Mobile Responsive Styles */
@media (max-width: 1024px) {
    .info-cards {
        flex-wrap: wrap;
        justify-content: center;
    }

    .card {
        width: 45%; /* Adjust width for tablets */
        margin: 10px; /* Add some margin for spacing */
    }
}

@media (max-width: 768px) {
    .gallery-container {
        width: 90%; /* Adjust width for smaller screens */
    }

    .image-slide img {
        max-width: 100%; /* Make images responsive */
    }

    .info-cards {
        flex-direction: column;
        align-items: center;
    }

    .card {
        width: 80%; /* Adjust width for mobile screens */
        margin: 10px 0; /* Adjust margin for spacing */
    }
}

@media (max-width: 480px) {
    .section-title h2 {
        font-size: 1.8em; /* Adjust title font size for small screens */
    }

    .card {
        width: 100%; /* Full width for cards on very small screens */
        margin: 5px 0; /* Adjust margin for small screens */
    }
}
