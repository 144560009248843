/* Default Styles (Desktop) */
.banner-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure no overflow outside the container */
}

.banner-image {
    width: 100%; /* Ensure the image fills the container width */
    height: 400px; /* Adjust height automatically to maintain aspect ratio */
    object-fit: cover;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .banner-slide {
        width: 100%; /* Ensure the slide container takes the full width */
    }

    .banner-image {
        height: 250px; /* Adjust height for mobile screens */
    }
}

@media (max-width: 480px) {
    .banner-slide {
        width: 100%; /* Ensure the slide container takes the full width */
    }

    .banner-image {
        height: 200px; /* Further adjust height for smaller screens */
    }
}
