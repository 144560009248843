
.containermain{

    padding: 20px;
}
.container {
        background-color: #f9f9f9;
    font-family: 'Poppins', sans-serif;
    padding-left: 80px;
    padding-right: 80px;
   
}

.heading {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
}

.half-black {
    color: black;
}

.half-red {
    color: #e74c3c;
}


.content {
    display: flex;
    justify-content: space-between;
}

.news-section {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.news-item {
    display: flex;
    flex-direction: row;
    width: calc(33.33% - 10px); /* Three items in one line */
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    height: 100px;
}

.news-details {
    padding: 10px;
    text-align: left;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.news-title {
    font-size: 10px;
    color: #333;
    margin: 0 0 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.news-date {
    font-size: 10px;
    color: #888;
}

.news-image {
    width: 100px;
    object-fit: cover;
}

.events-section {
    width: 35%;
    border: 1px solid #e74c3c;
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
    height: 300px;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
}

.events-heading {
    color: #fff; /* Text color for better contrast */
    font-size: 20px;
    background-color: #e74c3c; /* Background color */
    width: 100%; /* Ensure it spans full width */
    padding: 10px; /* Add some padding */
    box-sizing: border-box; /* Include padding in the element's total width */
    margin: 0; /* Remove default margin */
    text-align: center; /* Center align the text */
    position: sticky; /* Make it sticky */
    top: 0; /* Stick to the top */
    z-index: 1; /* Ensure it appears above the scrolling content */
}

.events-list {
    list-style: none;
    padding: 0;
    margin: 0;
    overflow: hidden;
    flex: 1;
    position: relative;
}

@keyframes slide-up {
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(-100%);
    }
}

.event-item {
    font-size: 14px;
    color: #333;
    padding: 5px 5px;
    border-bottom: 1px solid #ddd;
    animation: slide-up 5s linear infinite; /* Adjusted duration */
}

.event-item:last-child {
    border-bottom: none;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
    .app {
        padding-left: 20px;
        padding-right: 20px;
    }

    .heading {
        font-size: 24px; /* Reduced font size for mobile view */
    }

    .content {
        flex-direction: column; /* Stack content vertically on mobile */
    }

    .news-section {
        flex-direction: column; /* Stack news items vertically */
        gap: 10px;
    }

    .news-item {
        width: 100%; /* Full width on mobile */
        flex-direction: column; /* Stack image and details vertically on mobile */
        height: auto; /* Adjust height for flexible content */
    }

    .news-image {
        width: 100%;
        height: auto; /* Adjust height for flexible content */
    }

    .news-title {
        font-size: 14px; /* Adjusted font size for mobile */
    }

    .news-date {
        font-size: 12px; /* Adjusted font size for mobile */
    }

    .events-section {
        width: 100%; /* Full width on mobile */
        height: auto; /* Adjust height for flexible content */
    }

    .events-heading {
        font-size: 18px; /* Adjusted font size for mobile */
    }

    .event-item {
        font-size: 12px; /* Adjusted font size for mobile */
        padding: 5px 10px; /* Adjusted padding for mobile */
    }
}


/* popUp style */
/* Styles for the popup */
.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .popup-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    position: relative;
  }
  
  .popup-close {
    background: #f44336;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .popup-title {
    font-size: 24px;
    margin-top: 0;
  }
  
  .popup-image {
    width: 100%;
    height: auto;
  }
  
  .popup-date {
    color: #555;
  }
  
  .popup-description {
    margin-top: 10px;
  }
  