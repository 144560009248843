.footer {
    display: flex;
    justify-content: space-between;
    background-color: #2C2C2C;
    color: white;
    padding: 20px;
}

.footer-section {
    flex: 1;
    margin: 0 15px;
}

.footer-section h4 {
    margin-bottom: 10px;
}

.footer-section p,
.footer-section address {
    margin-bottom: 10px;
    line-height: 1.6;
}

.footer-section ul {
    list-style: none;
    padding: 0;
}

.footer-section ul li {
    margin-bottom: 5px;
}

.footer-section ul li a {
    color: #999;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.social-links a {
    margin-right: 10px;
    color: #e74c3c;
    font-size: 20px;
}

.footer-bottom {
    background-color: #2C2C2C;
    color: white;
    text-align: center;
    padding-top: 25px;
    padding-bottom: 20px;
}

.footer-bottom p {
    margin: 0;
    line-height: 1.6;
}

/* Mobile View */
@media (max-width: 767px) {
    .footer {
        flex-direction: column;
        align-items: center;
        padding: 10px;
    }

    .footer-section {
        margin-bottom: 20px;
        text-align: center;
    }

    .footer-section ul {
        padding: 0;
    }

    .footer-bottom {
        padding-top: 15px;
        padding-bottom: 15px;
    }
}
