
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.icons-container {
  transition: opacity 0.3s ease-in-out;
}

.icons-container.hidden {
  opacity: 0;
  display: none; /* Optionally hide the component */
}

.icons-container.visible {
  opacity: 1;
  display: block; /* Ensure the component is visible */
}
.sidebar_sub_menu {
  list-style-type: none;
  padding: 0;
}

.sidebar_sub_menu li {
  margin: 0;
}

.iconAdd {
  cursor: pointer;
}

.icon_close {
  cursor: pointer;
}

/* Default styles for desktop view */
.header-menu-area .logo img {
  height: 60px; /* Default height for the logo */
}

.header-menu-area h3 {
  font-size: 24px; /* Default font size for the heading */
}

/* Mobile styles */
@media (max-width: 768px) {
  .header-menu-area .logo img {
    height: 80px; /* Increased height for mobile view */
  }

  .header-menu-area h3 {
    font-size: 18px; /* Smaller font size for mobile view */
  }
}

/* custom */
/* App.css */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}





