/* Default (Desktop) Styles */
.navbarmy {
    position: fixed;
    background-color: #363636;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    top: 50; /* Initially position the navbar with a 50px top offset */
    left: 0;
    width: 100%; /* Ensures the navbar spans the full width of the viewport */
    z-index: 1000; /* Keeps the navbar above other content */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds a shadow for better visibility */
    transition: top 0.3s ease-in-out; /* Smooth transition for top change */
}

/* Fixed Position Styles When Scrolling */
.navbarmy.fixed {
    top: 0; /* Adjust the position when scrolling */
}

.logo {
    display: flex;
    align-items: center;
}

.logo img {
    height: 85px;
    margin-right: 10px;
}

.nav-linksmy {
    list-style: none;
    display: flex;
    margin: 0 auto;
    padding: 0;
}

.nav-linksmy li {
    margin-right: 20px;
}

.nav-linksmy a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    align-items: center;
    transition: color 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.nav-linksmy a i {
    margin-right: 8px;
}

/* Hover Effect */
.nav-linksmy a:hover {
    color: #e74c3c; /* Change text color on hover */
}

.navbarmy .nav-linksmy {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    gap: 10px;
}

.navbarmy .logo {
    margin-right: auto;
}

/* Mobile Styles */
@media (max-width: 768px) {
    .navbarmy {
        margin-right: 0;
        flex-direction: column;
        padding: 5px;
        text-align: start;
        width: 100%;
    }

    .logo img {
        height: 60px; /* Adjust logo size for mobile */
        margin-bottom: 10px;
    }

    .nav-linksmy {
        margin-left: 0;
        display: block;
        padding: 0;
    }

    .nav-linksmy li {
        margin: 10px 0;
    }

    .nav-linksmy a {
        font-size: 14px; /* Adjust font size for mobile */
    }

    .nav-linksmy a i {
        margin-right: 5px;
    }
}
