.container{
 
  padding: 20px;
  width: 100%;
}


  .app {
    display: flex;
    padding: 20px;
    justify-content: center;
    align-items: center;
    padding-left: 200px;
   padding-right: 200px;
    background-color: #f9f9f9;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr); /* Default: 6 columns for larger screens */
    gap: 30px;
    width: 100%;
    padding: 0 0px;
  }
  
  .grid-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  
  .icon-container {
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 5px solid white;
    margin-bottom: 10px;
    overflow: hidden;
  }
  
  .icon {
    width: 100%;
    height: auto;
  }
  
  .label {
    font-size: 14px;
    font-weight: bold;
  }
  
  /* Mobile Responsive Styles */
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on medium screens */
    }
  
    .icon-container {
      width: 80px; /* Adjust icon size for medium screens */
      height: 80px;
    }
  
    .label {
      font-size: 12px; /* Smaller font size for labels */
    }
  }
  
  @media (max-width: 480px) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr); /* 3 columns on small screens as well */
      gap: 20px; /* Reduced gap for small screens */
    }
  
    .icon-container {
      width: 100px; /* Further reduce icon size for small screens */
      height: 100px;
    }
  
    .label {
      font-size: 10px; /* Further reduce font size for labels */
    }
  }
  