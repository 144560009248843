.contact-us {
  display: flex;
  flex-direction: column;
  padding: 20px;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}

.contact-info1 {
  flex: 1;
  padding-right: 20px;
}

.contact-title {
  border-bottom: 3px solid red;
  display: inline-block;
  padding-bottom: 5px;
}

.contact-details {
  margin-top: 20px;
}

.contact-details h3 {
  margin-bottom: 10px;
}

.contact-details p {
  margin: 5px 0;
}

.contact-details a {
  color: blue;
  text-decoration: none;
}

.social-links {
  margin-top: 20px;
}

.social-links a {
  margin-right: 10px;
  font-size: 24px;
  color: #333;
  text-decoration: none;
}

.social-links a:hover {
  color: #007bff;
}

.location-map {
  flex: 1;
  margin-top: 20px;
}

.location-title {
  border-bottom: 3px solid orange;
  display: inline-block;
  padding-bottom: 5px;
}

.map-container {
  margin-top: 20px;
}

.map {
  width: 100%;
  height: 300px;
  border: 0;
}

@media (min-width: 768px) {
  .contact-us {
    flex-direction: row;
    padding-left: 100px;
    padding-right: 100px;
  }

  .location-map {
    margin-top: 0;
  }
}
